window.addEventListener('DOMContentLoaded', function () {
    document.querySelector('.content').classList.add('with-language');
    const languageSelection = document.querySelector('[data-language]');

    function changeLanguage(e) {
        const isMobile = e.target.hasAttribute('data-mobile');
        let selector = 'select[data-desktop]';
        if (isMobile) {
            selector = 'select[data-mobile]';
        }
        const language = languageSelection.querySelector(selector).value;
        document.querySelector('.content').classList.remove('with-language');
        if (language === window.language) {
            languageSelection.classList.add('uk-hidden');
        } else {
            let queryParam = '?lang=' + language;
            if (window.location.search) {
                queryParam = '&lang=' + language;
            }
            location.replace(window.location.href + queryParam)
        }
    }

    if (languageSelection) {
        languageSelection.querySelectorAll('[data-change-language]')
            .forEach(button => button.addEventListener('click', changeLanguage))
        languageSelection.querySelectorAll('[data-close-language]')
            .forEach(close => close.addEventListener('click', () => {
                    document.querySelector('.content').classList.remove('with-language');
                })
            );
    }

});